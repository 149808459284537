<template>
  <v-row>
    <v-col
      cols="6"
      offset="3"
    >
      <v-card
        v-if="settings"
      >
        <v-card-title>
          Settings
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="settings.busyMessage"
            outlined
            dense
            label="Busy Message"
            :readonly="!user.isAgent && !user.isSuperAdmin"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submitSettings"
          >
            Save Settings
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="user.isSuperAdmin"
        class="mt-5"
      >
        <v-card-title>
          Signup Call Linker
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="linkCallsToSignups"
          >
            Link Calls To Signups
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="user.isSuperAdmin"
        class="mt-5"
      >
        <v-card-title>
          Build Communities
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Community Name
                  </th>
                  <th class="text-right">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in buildCommunities"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td class="d-flex justify-end">
                    <v-icon
                      small
                      class="mr-2"
                      @click="viewPlans(item)"
                    >
                      mdi-eye
                    </v-icon>
                    <v-icon
                      @click="deleteBuildCommunity(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="newBuildCommunity"
          >
            Add Build Community
          </v-btn>
          <!-- <NewBuildCommunity /> -->
        </v-card-actions>
      </v-card>
      <v-card
        v-if="user.isSuperAdmin"
        class="mt-5"
      >
        <v-card-title>
          Client Notifications
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
          >
            Add Client Notification Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getSettings, updateSettings } from '@/shared/apis/userApi';
import { linkCallsToSignups } from '@/shared/apis/signupApi';
import { ROLES } from '@/shared/constants/roleConstants';
import userMixin from '@/components/mixins/userMixin';
import NewBuildCommunity from '@/components/NewBuildCommunity.vue';
import Plans from '@/components/Plans.vue';
import { getBuildCommunities, createBuildCommunity, deleteBuildCommunity } from '@/shared/apis/buildCommunityApi';
import { create } from 'vue-modal-dialogs';
import ConfirmDialog from '@/components/elements/ConfirmDialog.vue';

const newBuildCommunityDialog = create(NewBuildCommunity);
const viewPlansDialog = create(Plans);
const deleteBuildCommunityDialog = create(ConfirmDialog);

export default {
  name: 'Settings',
  components: {
  },
  mixins: [userMixin],
  data() {
    return {
      settings: null,
      roles: [],
      buildCommunities: [],
    };
  },
  computed: {
    userId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  async created() {
    this.settings = await getSettings();
    this.buildCommunities = await getBuildCommunities();
    this.roles = ROLES;
  },
  methods: {
    async submitSettings() {
      const data = {
        busyMessage: this.settings.busyMessage,
      };
      const result = await updateSettings(data);

      if (result) {
        this.$store.commit('showMessage', { content: 'Settings Updated.', color: 'success' });
      } else {
        this.$store.commit('showMessage', { content: 'Error occurred while updating settings.', color: 'danger' });
      }
      this.settings = await getSettings();
    },
    async linkCallsToSignups() {
      try {
        const result = await linkCallsToSignups();
        if (result) {
          this.$store.commit('showMessage', { content: result.message, color: 'success' });
        }
      } catch (e) {
        this.$store.commit('showMessage', { content: 'Error occurred while linking calls to signups.', color: 'danger' });
      }
    },
    async newBuildCommunity() {
      const result = await newBuildCommunityDialog();

      if (!result) return;

      try {
        await createBuildCommunity(result);
        this.$store.commit('showMessage', { content: 'Build Community Created.', color: 'success' });
        this.buildCommunities = await getBuildCommunities();
      } catch (error) {
        console.error(error);
        this.$store.commit('showMessage', { content: 'an error occurred while creating build community.', color: 'error' });
      }
    },
    async viewPlans(buildCommunity) {
      await viewPlansDialog({
        buildCommunity,
      });
    },
    async deleteBuildCommunity(buildCommunity) {
      const result = await deleteBuildCommunityDialog({
        title: 'Are you sure?',
        content: 'Are you sure you want to delete the build community?',
        confirm: 'Delete Build Community',
        cancel: 'Cancel',
        swapButtonColors: true,
      });

      if (!result) return;

      await deleteBuildCommunity(buildCommunity.id);
      this.$store.commit('showMessage', { content: 'Build Community deleted.', color: 'success' });
      this.buildCommunities = await getBuildCommunities();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
