<template>
  <div>
    <v-dialog
      :value="true"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{on, attrs}">
        <slot
          name="activator"
          v-bind="{on, attrs}"
        />
      </template>
      <v-card>
        <v-card-title class="headline">
          Edit Plan - {{ buildCommunityName }}
          <v-spacer />
          <v-btn
            icon
            @click="$close(false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="plan.name"
            outlined
            dense
            placeholder="Name"
          />
          <v-text-field
            v-model="plan.downloadSpeed"
            outlined
            dense
            label="Download Speed (Mbps)"
          />
          <v-text-field
            v-model="plan.uploadSpeed"
            outlined
            dense
            label="Upload Speed (Mbps)"
          />
          <v-textarea
            v-model="plan.benefits"
            outlined
            dense
            label="Benefits"
            placeholder="Enter each benefit on a new line"
          />
          <v-text-field
            v-model="plan.price"
            outlined
            dense
            label="Price"
          />
          <v-text-field
            v-model="plan.promoPrice"
            outlined
            dense
            label="Promo Price"
          />
          <v-text-field
            v-model="plan.planLength"
            outlined
            dense
            label="Plan Length (Months)"
          />
          <v-text-field
            v-model="plan.promoLength"
            outlined
            dense
            label="Promo Length (Months)"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submitPlan()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updatePlan } from '@/shared/apis/planApi';

export default {
  name: 'AddPlan',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    buildCommunityName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async submitPlan() {
      try {
        await updatePlan(this.plan.id, this.plan);
        this.$store.commit('showMessage', { content: 'Plan Updated.', color: 'success' });
        this.$close(true);
      } catch (error) {
        console.error(error);
        this.$store.commit('showMessage', { content: 'an error occurred while updating plan.', color: 'error' });
      }
    },
  },

};
</script>

<style scoped>
/* Add any styles here if necessary */
</style>
