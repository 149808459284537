<template>
  <div>
    <v-dialog
      :value="true"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline">
          Plans - {{ buildCommunity.name }}
          <v-spacer />
          <v-btn
            icon
            @click="$close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-simple-table
            v-if="plans.length"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Plan Name
                  </th>
                  <th class="text-left">
                    Download Speed
                  </th>
                  <th class="text-left">
                    Upload Speed
                  </th>
                  <th class="text-left">
                    Benefits
                  </th>
                  <th class="text-left">
                    Price
                  </th>
                  <th class="text-left">
                    Promo Price
                  </th>
                  <th class="text-left">
                    Plan Length (months)
                  </th>
                  <th class="text-left">
                    Promo Length (months)
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in plans"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.downloadSpeed }}</td>
                  <td>{{ item.uploadSpeed }}</td>
                  <td>{{ item.benefits }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.promoPrice }}</td>
                  <td>{{ item.planLength }}</td>
                  <td>{{ item.promoLength }}</td>
                  <td class="d-flex">
                    <v-icon @click="editPlan(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon @click="deletePlan(item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else>
            No Plans Found
          </div>
        </v-card-text>

        <v-card-actions>
          <AddPlan
            :build-community="buildCommunity"
            @plan-created="loadPlans"
          />
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createBuildCommunity } from '@/shared/apis/buildCommunityApi';
import { getPlans, deletePlan } from '@/shared/apis/planApi';
import { create } from 'vue-modal-dialogs';
import ConfirmDialog from '@/components/elements/ConfirmDialog.vue';
import AddPlan from './AddPlan.vue';
import EditPlan from './EditPlan.vue';

const editPlanDialog = create(EditPlan);
const deletePlanDialog = create(ConfirmDialog);

export default {
  name: 'Plans',
  components: {
    AddPlan,
  },
  props: {
    buildCommunity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      plans: [],
    };
  },
  async mounted() {
    await this.loadPlans();
  },
  methods: {
    async loadPlans() {
      this.plans = await getPlans(this.buildCommunity.id);
    },
    async submitBuildCommunity() {
      try {
        await createBuildCommunity(this.buildCommunity);
        this.$store.commit('showMessage', { content: 'Build Community Created.', color: 'success' });
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.$store.commit('showMessage', { content: 'an error occurred while creating build community.', color: 'error' });
      }
    },
    async editPlan(plan) {
      // open edit plan dialog
      await editPlanDialog({
        buildCommunityName: this.buildCommunity.name,
        plan,
      });
    },
    async deletePlan(plan) {
      const shouldDeletePlan = await deletePlanDialog({
        title: 'Are you sure?',
        content: 'Are you sure you want to delete the plan?',
        confirm: 'Delete Plan',
        cancel: 'Cancel',
        swapButtonColors: true,
      });

      if (!shouldDeletePlan) return;

      await deletePlan(plan.id);
      this.$store.commit('showMessage', { content: 'Plan Deleted.', color: 'success' });
      await this.loadPlans();
    },
  },

};
</script>

<style scoped>
/* Add any styles here if necessary */
</style>
