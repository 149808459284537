<template>
  <div>
    <v-btn
      @click="dialog = true"
    >
      Add Plan
    </v-btn>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline">
          Add Plan - {{ buildCommunity.name }}
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="plan.name"
            outlined
            dense
            placeholder="Name"
          />
          <v-text-field
            v-model="plan.downloadSpeed"
            outlined
            dense
            label="Download Speed (Mbps)"
          />
          <v-text-field
            v-model="plan.uploadSpeed"
            outlined
            dense
            label="Upload Speed (Mbps)"
          />
          <v-textarea
            v-model="plan.benefits"
            outlined
            dense
            label="Benefits"
            placeholder="Enter each benefit on a new line"
          />
          <v-text-field
            v-model="plan.price"
            outlined
            dense
            label="Price"
          />
          <v-text-field
            v-model="plan.promoPrice"
            outlined
            dense
            label="Promo Price"
          />
          <v-text-field
            v-model="plan.planLength"
            outlined
            dense
            label="Plan Length (Months)"
          />
          <v-text-field
            v-model="plan.promoLength"
            outlined
            dense
            label="Promo Length (Months)"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submitPlan()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createPlan } from '@/shared/apis/planApi';

export default {
  name: 'AddPlan',
  props: {
    buildCommunity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      plan: {
        buildCommunityId: this.buildCommunity.id,
      },
    };
  },
  methods: {
    async submitPlan() {
      try {
        await createPlan(this.plan);
        this.$store.commit('showMessage', { content: 'Plan Created.', color: 'success' });
        this.dialog = false;
        this.$emit('plan-created');
      } catch (error) {
        console.error(error);
        this.$store.commit('showMessage', { content: 'an error occurred while creating plan.', color: 'error' });
      }
    },
  },

};
</script>

<style scoped>
/* Add any styles here if necessary */
</style>
