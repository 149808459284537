<template>
  <div>
    <v-dialog
      :value="true"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">
          Add Build Community
          <v-spacer />
          <v-btn
            icon
            @click="$close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="buildCommunity.name"
            outlined
            dense
            label="Community Name"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submitBuildCommunity()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'NewBuildCommunity',
  data() {
    return {
      buildCommunity: {},
    };
  },
  methods: {
    async submitBuildCommunity() {
      this.$close(this.buildCommunity);
    },
  },

};
</script>

<style scoped>
/* Add any styles here if necessary */
</style>
